<template>
    <div class="sidebar-page anchoEspecial" id="modificarAnchoMenu">
        <section class="sidebar-layout">
            <b-sidebar
                position="static"
                :mobile="mobile"
                class="ocultarMenuMobile"
                id="menuMobile"
                type="is-light"
                open
            >
                <div class="p-1">
                    <div class="block"> 
                    <h3 class="colorPrimary fontWeigth700"> </h3>
                    </div>
                    <b-menu class="is-custom-mobile">
                        <!-- v-if="userNode.admin === true" -->
                        <div v-if="userNode.admin === true">
                        <b-menu-list label="Administrador"></b-menu-list>                       
                        <b-button class="cerrarMobileMenu is-block" @click="closeMobileMenu">
                        <router-link :to="{ name: 'VAdmin' }">
                        <b-menu-list> 
                            <b-menu-item label="Corralones" icon="link"></b-menu-item>
                        </b-menu-list>                    
                        </router-link>
                        </b-button>
                        </div>
 

                        <div class="separadorMenu"></div>
                                 
                        <b-menu-list label="Corralon"></b-menu-list>                       
                        <b-button class="cerrarMobileMenu is-block" @click="closeMobileMenu">
                        <router-link :to="{ name: 'VCorralon' }">
                        <b-menu-list>
                            <b-menu-item label="Mi corralon" icon="link"></b-menu-item>
                        </b-menu-list>                    
                        </router-link>
                        </b-button>
                        <div class="separadorMenu"></div>

                        <b-button class="cerrarMobileMenu is-block" @click="closeMobileMenu">
                        <router-link :to="{ name: 'VBudgetHistory' }">
                        <b-menu-list>
                            <b-menu-item label="Historial de presupuestos" icon="link"></b-menu-item>
                        </b-menu-list>                    
                        </router-link>
                        </b-button>
                        
                        <div class="separadorMenu"></div>

                        <b-menu-list label="Cuenta"></b-menu-list>
                        <b-button class="cerrarMobileMenu" @click="closeMobileMenu">
                        <router-link :to="{ name: 'VUserProfile' }">
                        <b-menu-list>
                            <b-menu-item label="Mi informacion" icon="link"></b-menu-item>
                        </b-menu-list>
                        </router-link>
                        </b-button>
                        <div class="separadorMenu"></div>


                        <b-button class="cerrarMobileMenu is-block" @click="cerrarSesion">
                        <b-menu-list>
                            <b-menu-item icon="logout" label="Cerrar sesión"></b-menu-item>
                        </b-menu-list>
                        </b-button>

                    </b-menu>
                </div>
            </b-sidebar>
        
        </section>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: 'CmpSidebar',
    data() {
        return {
 
        };
    },
    mounted() {
            this.searchDataUser({
                email: this.user.email,
                uid: this.user.uid
            });
        },
    methods: {
       ...mapActions('sessions',['cerrarSesion','searchDataUser']),
       ...mapActions('generalFunctions',['closeMobileMenu', 'menuMobile']),
    },
    computed:{
        // Contecto a la variable Usuario dentro del State, para luego poder comprobar si existe un usuario conectado
        ...mapState('generalFunctions',['mobile']),
        ...mapState('sessions',['userNode','user']),
        ...mapGetters('sessions',['userExist'])
    },
};
</script>

<style lang="scss">

.sidebar-page {
    height:100%;
    display: inline-flex;
    flex-direction: column;
    width: 260px;
    min-height: 100%;
    z-index: 1;
    position: absolute;

    .sidebar-content {
        height:100%;
        box-shadow: 1px 0px 2px 0px rgba(10, 10, 10, 0.06) !important;
    }
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
    }
}

@media screen and (max-width: 1023px) {
    .sidebar-page {
    z-index: 3;
    }

    .anchoEspecial {
    width: 0px;
    }

    .anchoEspecial260 {
    width: 260px;
    }

}

@media screen and (max-width: 1023px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini-mobile {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

/* MenuMobile */
/* MenuMobile */

@media screen and (max-width: 1023px) {

    .navbar-menu.is-active {
        display: block;
        z-index: 3!important;
        position: fixed!important;
        width: 100% !important;
    }

    .ocultarMenuMobile {
        display:none!important;
    }

    .efectoMenuMobile {
        animation-duration: 0.7s;
        animation-name: slideoff;
    }

    .mostrarMenuMobile {
        display:block!important;
        animation-duration: 0.7s;
        animation-name: slidein;    
    }

    @keyframes slidein {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: 0%;
    }
    }

     @keyframes slideoff {
    from {
     margin-left: 0%;
    }

    to {
        margin-left: 100%;
    }
    }



}


/* BarraMobile */
/* BarraMobile */

.p-1 {
    padding:10px!important;
}

.p-05 {
    padding:0.1px!important;
}

.cerrarMobileMenu {
    background-color:transparent!important;
    border:0px solid #333!important;
    padding:0px!important;
}



.textoBarraMobile {
    float:left;
    margin-top:15px;
}

.buttonBarraMobile {
    background-color:transparent;
    border: 0px solid #333;
    float:left;
    outline: none;
}

.fotoPerfil {
    width:50px;
    border-radius:50%;
    float:left;
    padding:8px;
}

.menu-list a.is-active {
    background-color: transparent!important;
    color: #4a4a4a!important;
}

@media screen and (max-width: 1023px) {

    .b-sidebar .sidebar-content.is-fullwidth-mobile {
        width: 100%;
        max-width: 100%;
        z-index: 3 !important;
        position: fixed !important;
    }

}

.separadorMenu {
    background-color:#e9e9e9;
    height:1px;
    width:150px; 
    margin-top:2px;   
    margin-bottom:2px; 
    margin-left:35px  
}

</style>
