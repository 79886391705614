<template>
    <div>
        <CmpBudgetHistory />
    </div>
</template>

<script>
// Llamo al mapState para poder recibir los datos del Usuario y comprobar si existe una Sesion
import { mapState } from "vuex";
import CmpBudgetHistory from '@/components/userSession/BudgetHistory.vue'

export default {
    name: 'VBudgetHistory',
    components: {
        CmpBudgetHistory
    },
}
</script>