<template>
    <div>
          <div class="barraMobile is-hidden-desktop">
                     
            <button class="buttonBarraMobile"  @click="menuMobile">
                <img
                    class="fotoPerfil"
                    src="@/assets/img/perfil/1.jpg"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                >
                <p class="colorPrimary textoBarraMobile">Mi cuenta</p>
            </button>

          </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";

    export default {

    name: 'CmpSidebarMobile',

    data() {
        return {

        };
    },

    methods: {
       ...mapActions('generalFunctions',['menuMobile']),
    },

    computed:{
 
    },

    }

</script>

<style scoped>

/* MenuMobile */
/* MenuMobile */

@media screen and (max-width: 1023px) {

    .navbar-menu.is-active {
        display: block;
        z-index: 30!important;
        position: fixed!important;
        width: 100% !important;
    }

    .ocultarMenuMobile {
        display:none!important;
    }

    .efectoMenuMobile {
        animation-duration: 0.7s;
        animation-name: slideoff;
    }

    .mostrarMenuMobile {
        display:block!important;
        animation-duration: 0.5s;
        animation-name: slidein;  
    }

    @keyframes slidein {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: 0%;
    }
    }

     @keyframes slideoff {
    from {
     margin-left: 0%;
    }

    to {
        margin-left: 100%;
    }
    }



}

/* BarraMobile */
/* BarraMobile */

.cerrarMobileMenu {
    background-color:transparent!important;
    border:0px solid #333!important;
    padding:0px!important;
}

.barraMobile {
  position: fixed;
  z-index:3;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFF;
  color: white;
  text-align: center;
  height:50px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.05);
}

.textoBarraMobile {
    float:left;
    margin-top:15px;
}

.buttonBarraMobile {
    background-color:transparent;
    border: 0px solid #333;
    float:left;
    outline: none;
    cursor:pointer;
}

.fotoPerfil {
    width:50px;
    border-radius:50%;
    float:left;
    padding:8px;
}

.menu-list a.is-active {
    background-color: transparent!important;
    color: #4a4a4a!important;
}

@media screen and (max-width: 1023px) {

    .b-sidebar .sidebar-content.is-fullwidth-mobile {
        width: 100%;
        max-width: 100%;
        z-index: 1 !important;
        position: fixed !important;
    }

}


</style>