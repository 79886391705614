<template>
    <div  v-if="userExist" class="marginTopSeccion">

  
            <CmpSidebar />          <!-- Sidebar 
            <CmpSidebarMobile />-->

            <div class="columns">
                <div class="column is-full p-0">
                    <div class="barraSeccion bgColorPrimary colorWhite title is-6 fontWeigth400 letterSpacing05">
                        Historial de presupuestos de <span v-if="!loader">{{userNode.username}}</span>       
                        <pulse-loader  :loading="loader" :color="colorLoader" v-if="loader" class="is-inline-block"></pulse-loader> 
                    </div>
                </div>
            </div>

            <div class="columns p-0 bgColorNotification">
                    <div class="column is-full">
                        <div class="containerResponsivo">         


                            <div                     
                             class="column is-full" style="padding-left:0px!important;margin-left:0px!important">         
                                    <table class="table bgTransparent" style="padding-left:0px!important;margin-left:0px!important">
                                        <thead>
                                            <tr >
                                                <th><abbr>Nombre</abbr></th>
                                                <th><abbr>Ciudad<span class="hiddeVisibility">aaaa</span> </abbr></th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody v-for="(corralon, index) in corralones" :key="index" >
                                            <tr>
                                                <td>{{corralon.name}}</td>
                                                <td>{{corralon.city.name}}</td>
                                                <td>
                                                    <b-button
                                                    type="is-success"
                                                    v-if="showBudgets === false"
                                                    native-type="button"
                                                    class="is-hidden-mobile"
                                                    @click="cityBudget(corralon.city.id);getBudgetsHistory(corralon.id);showBudgets = true">
                                                    <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'align-justify']"  /> Ver presupuestos
                                                    </b-button>
                                                                                                    <td>
                                                    <b-button
                                                    type="is-success"
                                                    v-if="showBudgets === false"
                                                    class="is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only is-hidden-fullhd"
                                                    native-type="button"
                                                    @click="cityBudget(corralon.city.id);getBudgetsHistory(corralon.id);showBudgets = true">
                                                    <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'align-justify']"  /> Ver
                                                    </b-button>
                                            </td>
                                            </tr>
                                        </tbody>

                                    </table>
                        </div> 

                            <p class="title is-7 mt-1 is-hidden-desktop is-hidden-fullhd">Para ver todos los datos completos ver en una computadora.
                            <p class="title is-7 mt-1" style="margin-left:10px" v-if="paginationEnd === true">Ya vio todos los presupuestos.</p>
                            <p class="title is-7 mt-1" style="margin-left:10px"
                            v-show="showBudgets === true && budgetsHistory.length < 1 && paginationEnd === false">
                            Todavía no recibió presupuestos.
                            </p>
     

                            <div v-if="showBudgets === true && budgetsHistory.length > 0">

                            <b-button
                            class="bgColorPrimary colorWhite is-hidden"
                            native-type="button"
                            @click="showMaterials = false;showBudgets = true">
                            <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'arrow-left']"  /> Volver a presupuestos
                            </b-button>

                            <b-button
                            v-if="showMaterials"
                            class="bgColorPrimary colorWhite"
                            native-type="button"
                            @click="showMaterials = false;showBudgets = true">
                            <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'arrow-left']"  /> Volver a presupuestos
                            </b-button>
                                
                                <table class="table bgTransparent" v-if="!showMaterials">
                                    <thead class="inline-block">
                                        <tr class="inline-block">
                                            <th><abbr >Nombre<span class="hiddeVisibility">aaaa</span></abbr></th>
                                            <th class="is-hidden-mobile is-hidden-tablet-only"><abbr >Email<span class="hiddeVisibility">aaaa</span></abbr></th>
                                            <th class="is-hidden-mobile is-hidden-tablet-only"><abbr title="Played">Fecha<span class="hiddeVisibility">aaaa</span></abbr></th>
                                            <th class="is-hidden-mobile is-hidden-tablet-only"><abbr title="Played">Telefono<span class="hiddeVisibility">aaaa</span></abbr></th>
                                            <th class="is-hidden-mobile is-hidden-tablet-only"><abbr title="Played">Observación<span class="hiddeVisibility">aaaa</span></abbr></th>
                                        </tr>
                                    </thead>
                                    <tbody  v-for="(budget, index) in budgetsHistory" :key="index">
                                        <tr>
                                            <td>{{budget.name}}</td>
                                            <td class="is-hidden-mobile is-hidden-tablet-only">{{budget.email}}</td>
                                  
                                            <td class="is-hidden-mobile is-hidden-tablet-only">{{budget.date.slice(0,10)}}</td>  
                                            <td class="is-hidden-mobile is-hidden-tablet-only">{{budget.phone}}</td>  
                                            <td class="is-hidden-mobile is-hidden-tablet-only">{{budget.observation}}</td>  
                                            <td class="is-hidden-mobile">
                                                <b-button
                                                type="is-success"
                                                native-type="button"
                                                @click="getMaterials({id:budget.id});showMaterials = true;budgetId(budget.id)">
                                                <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'align-justify']"  /> Ver materiales
                                                </b-button>
                                            </td>   
                                            <td class="is-hidden-tablet">
                                                <b-button
                                                type="is-success"
                                                native-type="button"
                                                @click="getMaterials({id:budget.id});showMaterials = true;budgetId(budget.id)">
                                                <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'align-justify']"  /> Ver
                                                </b-button>
                                            </td>                                              
                                        </tr>
                                    </tbody>
                                              
                                </table> 
                                <b-button
                                v-if="pageNumber != 1 && Object.keys(budgetsHistory).length >= 10"
                                class="bgColorSecondary colorWhite"
                                native-type="button"
                                @click="pagination('prev')">
                                <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'arrow-left']"  />
                                Anterior
                                </b-button>
                                <b-button
                                v-if="Object.keys(budgetsHistory).length >= 10 && paginationEnd === false"
                                class="bgColorSecondary colorWhite"
                                style="margin-left:10px"
                                native-type="button"
                                @click="pagination('next')">
                                Siguiente
                                <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'arrow-right']"  />
                                </b-button>

                                    <b-button
                                v-if="pageNumber != 1 && paginationEnd === true"
                                class="bgColorSecondary colorWhite"
                                native-type="button"
                                @click="pagination('comeBack')">
                                <font-awesome-icon class="subtitle is-6 colorWhite" :icon="['fa', 'arrow-left']"  />
                                Anterior
                                </b-button> 

                                <table class="table bgTransparent"  v-if="showMaterials"> 
                                    
                                            <thead class="inline-block">
                                                <tr class="inline-block">
                                                        <th><abbr >Nombre<span class="hiddeVisibility">aaaa</span></abbr></th>
                                                        <th class="is-hidden-mobile is-hidden-tablet-only"><abbr >Email<span class="hiddeVisibility">aaaa</span></abbr></th>
                                                        <th class="is-hidden-mobile is-hidden-tablet-only"><abbr title="Played">Fecha<span class="hiddeVisibility">aaaa</span></abbr></th>
                                                    </tr>
                                            </thead>
                                            <tbody  v-for="(budget, index) in budgetsHistory" :key="'Budget' + index">
                                                <tr v-if="budget.id === setBudgetId">
                                                    <td>{{budget.name}}</td>
                                                    <td class="is-hidden-mobile is-hidden-tablet-only">{{budget.email}}</td>
                                                    <td class="is-hidden-mobile is-hidden-tablet-only">{{budget.date.slice(0,10)}}</td>                                              
                                                </tr>
                                            </tbody>
                                            <thead class="inline-block">
                                                    <tr>
                                                    <th><abbr>Material</abbr></th>
                                                    <th><abbr>cantidad</abbr></th>
                                                </tr>
                                            </thead>
                                            <tbody class="inline-block"  v-for="(budgetMaterials, index) in materials" :key="index">
                                                <tr>     
                                                    <td v-if="budgetMaterials.quantity !== 0">{{budgetMaterials.product}}</td>
                                                    <td v-if="budgetMaterials.quantity !== 0">{{budgetMaterials.quantity}}</td>               
                                                </tr>              
                                            </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            </div>
       
    </div>
</template>

<script>

    import CmpSidebar from '@/components/userSession/sidebar/sidebar.vue'
    import CmpSidebarMobile from '@/components/userSession/sidebar/sidebarMobile.vue'
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import { mapState, mapActions, mapGetters } from "vuex";

    export default {
        
        name: 'CmpBudgetHistory',

        data() {
            return {
                budgetsHistory: [],
                materials: {},
                cityId: 0,
                corralonCreatedDate: 0,
                
                showBudgets: false,
                showMaterials: false,

                setBudgetId: null,

                paginationEnd: false,
                pageNumber: 1
            }
        },

        components: {
            CmpSidebar,
            CmpSidebarMobile,
            PulseLoader
        },

        created() {
            this.getUserData({
                user: this.user
            });
            //this.getBudgetsHistory();
           // this.fromRoute(this.$route.name);
           //this.getBudgetTest()
            this.getCorralones({
                firebaseId: this.user.uid,
            });

   
        },

        beforeMount(){
             this.cityBudget()
        },

        mounted () {
            this.goTop()
        },
 
        methods: {
        
            ...mapActions('sessions',['getUserData']),
            ...mapActions('corralon',['getCorralones']),
            ...mapActions('generalFunctions',['fromRoute']),

            goTop() {
             window.scrollTo(0, 0);
            },

            budgetId(id) {
                this.setBudgetId = id;
            },

            cityBudget(id){
                        this.cityId = id
                        //console.log(this.cityId)
                        //this.getBudgetsHistory()
                    },

            checkDate(id){
                    let idCorralon = id;
                    for (var i = this.corralones.length; i--;) {
                        if (this.corralones[i].id === idCorralon) {
                            this.corralonCreatedDate = this.corralones[i].createdAt
                        } else {
                          //  console.log('Este no')
                        }
                    }

                    },

           getBudgetsHistory(id){
               this.idCorralon = id;
               let idCorralon = id;
               //console.log('Id corralon' + idCorralon)
                this.axios.get('/farmyard/'+ idCorralon +'/budgets?limit=10&page=' + this.pageNumber)
                .then(res => {
                 // console.log('Presupuestos')
                 // console.log(res.data)
                    this.budgetsHistory = res.data.items;
                 // console.log('Largo array de corralones')
                 // console.log(Object.keys(res.data.items).length)
                    if (Object.keys(res.data.items).length < 10) {
                        this.paginationEnd = true
                    }
                    //console.log(this.budgetsHistory)
                })
                .catch(error => {
                    //console.log(error.response);
                })
            },

            pagination(value) {
              //  console.log('Valor ' + value)

                if (value === 'next') {
                    this.pageNumber = this.pageNumber + 1
                    this.getBudgetsHistory(this.idCorralon)
                 //   console.log('Pagina numero next' + this.pageNumber)
                } else if (value === 'prev' && this.pageNumber >= 2) {
                    this.pageNumber = this.pageNumber - 1
                    this.getBudgetsHistory(this.idCorralon)
                 //   console.log('Pagina numero prev ' + this.pageNumber)            
                } else if (value === 'comeBack') {
                    this.paginationEnd = false
                    this.pageNumber = this.pageNumber - 1
                    this.getBudgetsHistory(this.idCorralon)
                } else {
                  //  console.log('No se puede restar');
                }

                
            },

            getMaterials({id}){
                this.axios.get('budgets/' + id)
                .then(res => {
                 //   console.log('materiales')
                 //   console.log(res.data)
                    this.materials = res.data.budgetLineList;
                })
                .catch(error => {
                  //  console.log(error.response);
                })
            }

        },

        computed:{
            ...mapState('sessions',['user', 'userNode', 'loader', 'colorLoader']),
            ...mapState('corralon',['corralon','corralones']),
            ...mapGetters('sessions',['userExist'])
        },
        

    }

</script>

<style scoped>
    .marginTopSeccion {
        margin-top:12px;
    }

    .pt-1 {
        padding-top:10px;
    }

    .hiddeVisibility {
        visibility: hidden;
    }

    .anchoColumna {
        width:190px!important;
    }

    .paddingLeftTable {
        padding-left:0px!important;
    }

    .widthColumn {
        width:150px!important;
    }

    .bgTransparent {
        background-color:transparent!important;
    }

    .columns {
    margin-left: 0rem; 
    margin-right: 0rem; 
    margin-top: -0.75rem;
    }       


    .backgroundImg {
        background-image: url("../../assets/img/slider/1.jpg");
        background-size:cover;
    }



    .bgColorNotification {
        background-color:whitesmoke;
        border-radius:5px
    }

    .barraSeccion {
        margin-left:240px;
        margin-top:25px;
        padding-left:50px;
        padding-top:15px;
        padding-bottom:15px;
    }
 
    .containerResponsivo {
       margin-left:280px;
       margin-top:40px;
    }


    @media screen and (max-width: 1023px) {



        .containerResponsivo {
            margin-left:0px;
            margin-top:0px;
        }
            
        .barraSeccion {
            margin-left:0px;
            margin-top:0px;
            padding-left:30px;
            padding-top:15px;
            padding-bottom:15px;
        }
 
    }

    @media screen and (max-width: 420px) {

 

    }
    .separador {
        background-color:rgb(200, 200, 200);
        height:1px;
        width:200px; 
        margin-top:9px;   
        margin-bottom:9px;   
    }

    .fotoPerfilUsuario {
        width:150px;
        border-radius:50%;
        padding:8px;
    }
    
    .card-1 {
    box-shadow: 0 0px 2px 0px rgba(0,0,0,0.16), 0 0px 10px 2px rgba(0,0,0,0.07);
    }


/* Width */
/* Width */

.w100 {
    width:100%!important;
}

.maxW300 {
    max-width:300px!important;
}

.maxW200 {
    max-width:200px!important;
}

.maxW100 {
    max-width:100px!important;
}

.maxW50 {
    max-width:50px!important;
}



    /* TextStyle */
    /* TextStyle */

    .lineHeigth0 {
        line-height: 0px;
    }

    .lineHeigth1 {
        line-height: 30px;
    }

     .letterSpacing05 {
        letter-spacing: 0.5px;
    }

     .letterSpacing1 {
        letter-spacing: 1px;
    }

     .letterSpacing2 {
        letter-spacing: 2px;
    }

    .letterSpacing3 {
        letter-spacing: 3px;
    }
    


    /* Colors text */
    /* Colors text */

    .colorPrimary {
        color:#8752aa!important;
    }

    .colorSecondary {
        color:#ff7850!important;
    }

    .colorTertiary {
        color:#ffc627!important;
    }

    .colorGrey {
        color:rgb(112, 112, 112)!important;
    }

    .colorBlack {
        color:rgb(24, 24, 24)!important;
    }

    .colorSoftRed {
        color:rgb(219, 53, 53)!important;
    }


    .colorRed {
        color:red!important;
    }

    .colorYellow {
        color:rgb(233, 116, 21);
    }

    .colorSuccess {
        color:seagreen!important;
    }

    .colorWhite {
        color:rgb(255, 255, 255)!important;
    }


    /* Colors bg */
    /* Colors bg */

    .bgColorPrimary {
        background-color:#8752aa!important;
    }

    .bgColorSecondary {
        background-color:#ff7850!important;
    }

    .bgColorTertiary {
        background-color:#ffc627!important;
    }

    .bgColorSoftRed {
        background-color:#f64444!important;
    }

    .bgColorSoftBlue {
        background-color:#3d40fd!important;
    }


    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }


    /* Bordes */
    /* Bordes */

    .borderPrimary {
        border-color:#8752aa!important;
    }

    .borderSecondary {
        border-color:#ff7850!important;
    }

    .borderTertiary {
        border-color:#ffc627!important;
    }

    
    /* Padding */
    /* Padding */
    .p-0 {
        padding:0px!important;        
    }

    .p-1 {
        padding:20px!important;
    }

    .p-2 {
        padding:20px!important;
    }

    .p-3 {
        padding:30px!important;
    }

    .p-4 {
        padding:40px!important;
    }
    /* Margins */
    /* Margins */
   .m-0 {
        margin-top:0px!important;
    }

    .mt-1 {
        margin-top:10px!important;
    }

    .mb-1 {
        margin-bottom:10px!important;
    }


    .mt-2 {
        margin-top:20px!important;
    }

    .mb-2 {
        margin-bottom:20px!important;
    }


    .mt-8 {
        margin-top:80px!important;
    }

    .mb-8 {
        margin-bottom:80px!important;
    }


  </style>